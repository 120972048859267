:root {
  /* black and green theme */
  --black1: #F6F7FB;
  --green1: #1EA698;
  --buttton: #5AA6FF;
  --buttoncolor: white;
  overflow-x: hidden;
  font-family: __localCircularXXTTFont_29d464, __localCircularXXTTFont_Fallback_29d464, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-y: hidden;


}

::-webkit-scrollbar {
  display: none;
}



/* Loading... */
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
}

.btn-success {
  color: #ffffff !important;
  background: linear-gradient(89.93deg,
      #029046 0.07%,
      #4fb155 99.96%) !important;
  box-shadow: 0px 10px 20px rgba(79, 177, 85, 0.3) !important;
  border-radius: 10px !important;
  border: none !important;
}

.main_wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  margin-bottom: 7%;
}

.header-top {
  padding: 10px 20px;
}

::-webkit-scrollbar {
  margin-left: 10px;
  height: 10px;
  border-radius: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  height: 14px;
  background-color: #599bdc;
  border-radius: 10px;
}



.card {
  background: #ffffff;
  box-shadow: 0px 10px 60px rgba(11, 18, 36, 0.1);
  border-radius: 15px !important;
  border: none !important;
}

body {
  overflow: hidden;
  background-image: url('./assets/background/bg_abstract.png') !important;
  background-repeat: no-repeat;
  background-size: 100%;
}


/* Table */
.sc-dmctIk {
  box-shadow: 0px 10px 60px rgb(11 18 36 / 10%) !important;
  border-radius: 20px !important;
}


.rdt_Pagination {
  border: 0 !important;
}

.rdt_TableHead {
  background-color: #000000 !important;
  color: white !important;
}

.rdt_TableHeadRow {
  background-color: #000000 !important;
}