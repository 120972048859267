@font-face {
  font-family: "General Sans";
  src: url("../public/font/GeneralSans/Fonts/OTF/GeneralSans-Regular.otf");
}

body,
html {
  font-family: "Helvetica" !important;
}

html,
body,
#root,
.App {

  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  background: #F6F7FB;
  overflow-x: hidden;
  overflow-y: hidden;

}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: block;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  /* scrollbar-width: none; Firefox */
}